/* @flow */

import * as React from 'react';
import {Link} from 'shells/link';
import {NavButtons} from 'shells/modal/step-modal/layout';
import {MarketingHeader, MarketingBody} from 'shells/typography';
import {Button} from 'shells/button';
import {SelectMethodScreen} from '../screens/select-method-screen';
import type {Method} from '../types';

import '../select-method-page.css';

type Props = {|
    goNext: () => void,
    setSelectedMethod: (Method) => void,
    selectedMethod: Method,
    resetPhoneNumber: () => void,
|};

export function SelectMethodPage(props: Props) {
    props.resetPhoneNumber();

    return (
        <>
            <div styleName='container'>
                <MarketingHeader>But first…</MarketingHeader>

                <MarketingBody>
                    Your account admin has enabled <b>two-factor authentication</b> for all users on
                    your account. You’ll need to configure this before you log in to Nutshell
                </MarketingBody>

                <MarketingBody>Select a method to get started:</MarketingBody>

                <SelectMethodScreen
                    setSelectedMfaMethod={(method: Method) => {
                        props.setSelectedMethod(method);
                    }}
                    selectedMfaMethod={props.selectedMethod}
                />

                <Button variant={'text-primary'} styleName='info-link'>
                    Learn more about two-factor authentication
                </Button>
            </div>

            <NavButtons>
                <Link variant='button-primary' size='big' onClick={props.goNext}>
                    Next
                </Link>
            </NavButtons>
        </>
    );
}
