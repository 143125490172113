/* @flow */

import * as React from 'react';
import {Switch, Route, withRouter, type ContextRouter} from 'react-router-dom';

import {TinyIntroductionPage} from './tiny-introduction/tiny-introduction-page';
import {LoginRoot} from './login/login-root';
import {SignupPage} from './signup/signup-page';
import {JoinYourTeamPage} from './join-your-team/join-your-team-page';
import {VerifySenderPage} from './verify-sender/verify-sender-page';
import {ExpiredPage} from './expired-page';
import {PausedPage} from './paused-page';
import {VerifyEmailPage} from './verify-email/verify-email-page';
import {OldBrowserPage} from './old-browser-page';

type Props = ContextRouter;

/*
 * This is the top-level ui component for pages which are shown before authentication,
 * including signup, welcome screens, and login
 */

export class AuthRootComponent extends React.Component<Props> {
    componentDidMount(): void {
        this.props.history.listen((location) => {
            dataLayer.push({
                event: 'clientPageview',
                pageUrl: location.pathname,
            });
        });
    }

    render() {
        return (
            <Switch>
                <Route path='/auth/expired' component={ExpiredPage} />
                <Route path='/auth/paused' component={PausedPage} />
                <Route path='/auth/join-your-team' component={JoinYourTeamPage} />
                <Route path='/auth/old-browser' component={OldBrowserPage} />
                <Route path='/auth' component={LoginRoot} />
                <Route path='/my-account/verify-email' component={VerifyEmailPage} />
                <Route path='/email-verify/success' component={VerifySenderPage} />
                <Route path='/email-verify/failure' component={VerifySenderPage} />
                <Route path='/signup' component={SignupPage} />
                <Route path='/welcome/:slideId' component={TinyIntroductionPage} />
            </Switch>
        );
    }
}

export const AuthRoot = withRouter<{||}>(AuthRootComponent);
