/* @flow */

import * as React from 'react';
import {Button} from 'shells/button';
import {NavButtons} from 'shells/modal/step-modal/layout';
import {MarketingHeader, Body} from 'shells/typography';
import {ViewRecoveryCodesScreen} from '../screens/view-recovery-codes-screen';
import type {Method} from '../types';

import '../modal/recovery-codes.css';

type Props = {|
    goNext: () => void,
    method: Method,
|};

export function RecoveryCodesPage(props: Props) {
    const [continueButtonDisabled, setContinueButtonDisabled] = React.useState(true);

    return (
        <>
            <div styleName='top'>
                <MarketingHeader>Everything’s been verified!</MarketingHeader>
                {props.method === 'sms' && (
                    <Body>From now on, we’ll text you a code to enter whenever you log in</Body>
                )}

                {props.method === 'app' && (
                    <Body>From now on, you’ll enter a code from an app whenever you log in</Body>
                )}
            </div>

            <ViewRecoveryCodesScreen
                onNextReady={() => {
                    setContinueButtonDisabled(false);
                }}
                method={props.method}
            />

            <NavButtons>
                <Button
                    variant='primary'
                    size='big'
                    onClick={props.goNext}
                    disabled={continueButtonDisabled}
                >
                    I saved these codes
                </Button>
            </NavButtons>
        </>
    );
}
