/* @flow */

export const RECOVERY_CODES_APP = '/connect-mfa/app/recovery-codes';
export const RECOVERY_CODES_SMS = '/connect-mfa/sms/recovery-codes';
export const SELECT_METHOD = '/connect-mfa/select-method';
export const VERIFY_APP = '/connect-mfa/verify-app';
export const VERIFY_SMS = '/connect-mfa/verify-sms';
export const VERIFY_SMS_PHONE = '/connect-mfa/verify-sms-phone';
export const DONE_APP = '/connect-mfa/app/done';
export const DONE_SMS = '/connect-mfa/sms/done';

export const TYPE_APP = '0';
export const TYPE_SMS = '1';
export const TYPE_RECOVERY = '2';
