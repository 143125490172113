/* @flow */

import * as React from 'react';
import {type FieldProps} from 'redux-form';

import {FormField} from 'shells/form';
import {TextField} from 'shells/textfield';

import {TinyIntroCompanyLogo} from './tiny-intro-company-logo';

import './tiny-intro-company-name-logo.css';

type Props = {
    title: string,
    companyLogoUrl: FieldProps,
    companyName: FieldProps,
    isRequired?: boolean,
};

export function TinyIntroCompanyNameLogo(props: Props & FieldProps) {
    return (
        <FormField
            innerId={props.companyName.input.name}
            label={props.title}
            errorMessage={
                props.companyName.meta.error && props.companyName.meta.submitFailed
                    ? props.companyName.meta.error
                    : null
            }
            isRequired={props.isRequired}
        >
            <div styleName='company-container'>
                {props.companyLogoUrl.input.value ? (
                    <TinyIntroCompanyLogo
                        {...props.companyLogoUrl}
                        onRemove={() => {
                            props.companyLogoUrl.input.onChange(null);
                        }}
                    />
                ) : null}
                <TextField
                    type='text'
                    {...props.companyName.input}
                    id={props.companyName.input.name}
                    hasError={props.companyName.meta.error && props.companyName.meta.submitFailed}
                    size='big'
                    placeholder='ExampleCo'
                />
            </div>
        </FormField>
    );
}
