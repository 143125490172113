/* @flow */

import {ValidationError} from './validation-error-check';

/*
 * This function takes a ValidationError and converts it into an object of the form that can
 * be used in redux-form as its standard error object, where `_error` is the global error key,
 * and field-level errors get their own keys.
 */
export function makeErrorObject(error: Object) {
    if (!error || !(error instanceof ValidationError)) return {};

    if (Array.isArray(error.validationResult.messages)) {
        return {
            _error: error.validationResult.messages[0],
        };
    }

    return error.validationResult.messages;
}
