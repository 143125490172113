/* @flow */

import * as React from 'react';
import confetti from 'canvas-confetti';
import type {Method} from '../types';
import {MfaBody} from '../typography/index';

type Props = {|
    method: Method,
    canvasRef: React.Ref<*>,
|};

export function DoneScreen(props: Props) {
    // Confetti animation
    React.useEffect(() => {
        /* $FlowIgnore */
        const canvas = props.canvasRef.current;

        if (canvas instanceof HTMLCanvasElement) {
            canvas.confetti = canvas.confetti || confetti.create(canvas, {resize: true});
            const colors = ['#3B9ED7', '#62D5CA', '#3D3B8A', '#FF7033', '#33C252', '#BDEAFF'];
            canvas.confetti({
                origin: {y: 1},
                spread: 70,
                colors: colors,
                particleCount: 75,
            });
        }
    }, [props.canvasRef]);

    return (
        <>
            {props.method === 'sms' && (
                <MfaBody>
                    Next time you log in to Nutshell, you’ll also enter a code that we text you.
                </MfaBody>
            )}

            {props.method === 'app' && (
                <MfaBody>
                    Next time you log in to Nutshell, you’ll also enter a code from your
                    authentication app.
                </MfaBody>
            )}
        </>
    );
}
