/* @flow */

const separator = ' · ';

export function headTitle(input: string | string[]) {
    let prefix = input;
    if (Array.isArray(input)) {
        prefix = input.join(separator);
    }

    return [prefix, 'Nutshell'].join(separator);
}
