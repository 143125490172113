/* @flow */

import * as React from 'react';
import {withRouter, type ContextRouter} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import type {FormProps} from 'redux-form/lib/types.js.flow';

import {Subheader} from 'shells/typography';
import {Button} from 'shells/button';
import {ErrorMessage} from 'shells/form';

import {SET_NEW_PASSWORD_REDUX_FORM} from '../../constants';
import {TinyIntroTextField} from '../../fields/tiny-intro-text-field';

import './set-new-password-form.css';

function validate(values: Object) {
    const errors = {};
    if (!values.password1) {
        errors.password1 = 'Please enter a password';
    }
    if (!values.password2) {
        errors.password2 = 'Please confirm your password';
    }

    return errors;
}

type OwnProps = {|
    // We'll show different copy accordingly
    isNewUser: boolean,
|};

type Props = {...$Exact<FormProps>, ...OwnProps, ...ContextRouter};

export class SetNewPasswordFormComponent extends React.Component<Props> {
    componentDidUpdate(prevProps: Props) {
        if (this.props.submitSucceeded !== prevProps.submitSucceeded) {
            if (this.props.submitSucceeded) {
                this.props.history.push('/auth/reset-success');
            }
        }
    }
    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                {this.props.isNewUser ? (
                    <Subheader>Welcome to Nutshell! Please set a password to continue.</Subheader>
                ) : (
                    <Subheader>Please choose and confirm your new password.</Subheader>
                )}
                <div styleName='fields'>
                    <Field
                        key='password'
                        name='password'
                        placeholder='··········'
                        title='New password'
                        type='password'
                        autoFocus={true}
                        component={TinyIntroTextField}
                    />
                    <Field
                        key='passwordConfirm'
                        name='passwordConfirm'
                        placeholder='··········'
                        title='Confirm password'
                        type='password'
                        component={TinyIntroTextField}
                    />
                </div>
                <div styleName='button submit'>
                    <Button
                        disabled={this.props.submitting}
                        variant='primary'
                        type='submit'
                        size='xlarge'
                        isFullWidth={true}
                    >
                        {this.props.isNewUser ? 'Set password' : 'Change password'}
                    </Button>
                </div>
                {this.props.submitFailed ? <ErrorMessage>{this.props.error}</ErrorMessage> : null}
            </form>
        );
    }
}

// $FlowIgnore
export const SetNewPasswordForm = withRouter(
    reduxForm({form: SET_NEW_PASSWORD_REDUX_FORM, validate})(SetNewPasswordFormComponent)
);
