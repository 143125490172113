/* @flow */

import * as React from 'react';
import {Field} from 'redux-form';

import {Body} from 'shells/typography';

import {TinyIntroCheckbox, TinyIntroTextField} from '../fields';

import './phone-and-text-fields.css';

type Props = {companyLogoUrl: ?string};

export function PhoneAndTextFields(props: Props) {
    return (
        <div styleName='fields'>
            <Field
                key='workPhone'
                name='workPhone'
                placeholder='(123) 456-7890'
                title='What’s your work phone number?'
                type='text'
                autoFocus={props.companyLogoUrl}
                component={TinyIntroTextField}
            />
            <Field
                key='textMessageOptIn'
                name='textMessageOptIn'
                title={
                    <Body>
                        The Nutshell team can send me personal text messages about this trial. Opt
                        out any time. (optional)
                    </Body>
                }
                component={TinyIntroCheckbox}
                isLarge={false}
                isTopAligned={true}
                customCheckboxStyle={{margin: '4px 6px 0 0'}}
            />
        </div>
    );
}
