/* @flow */

import * as React from 'react';
import {reduxForm} from 'redux-form';
import type {FormProps} from 'redux-form/lib/types.js.flow';
import classnames from 'classnames';

import {Header, Subheader} from 'shells/typography';
import {Line} from 'shells/divider';
import {Button} from 'shells/button';

import {TINY_INTRO_REDUX_FORM} from '../../constants';
import type {SlideQuestion} from '../tiny-introduction';

import './question-slide.css';

type State = {
    value: string,
};

type OwnProps = {|
    slideQuestion: SlideQuestion,
    isTwoColumns?: boolean,
    isWide?: boolean,
    hideButton?: boolean,
|};

type Props = {...OwnProps, ...$Exact<FormProps>};

export class QuestionSlideComponent extends React.Component<Props, State> {
    render() {
        const styleNames = classnames('question-slide', {
            'question-slide--two-columns': this.props.isTwoColumns,
            'question-slide--wide': this.props.isWide,
        });

        return (
            <div styleName={styleNames}>
                <Header>{this.props.slideQuestion.title}</Header>
                <Subheader>{this.props.slideQuestion.subtitle}</Subheader>
                <form onSubmit={this.props.handleSubmit}>
                    {this.props.slideQuestion.fields}
                    <div styleName='info-message'>{this.props.slideQuestion.infoMessage}</div>
                    {!this.props.hideButton ? (
                        <Button
                            type='submit'
                            disabled={this.props.slideQuestion.shouldPreventNext}
                            variant='primary'
                            size='xlarge'
                            isFullWidth={true}
                        >
                            {this.props.slideQuestion.nextText
                                ? this.props.slideQuestion.nextText
                                : 'Next'}
                        </Button>
                    ) : (
                        undefined
                    )}

                    {this.props.slideQuestion.footer ? (
                        <React.Fragment>
                            <Line />
                            {this.props.slideQuestion.footer}
                        </React.Fragment>
                    ) : (
                        undefined
                    )}
                </form>
            </div>
        );
    }
}

export const QuestionSlide = reduxForm({
    form: TINY_INTRO_REDUX_FORM,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(QuestionSlideComponent);
