/* @flow */

import * as React from 'react';
import {type ContextRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {PreAuthLayout} from 'shells/layout';
import {colors} from 'shells/colors';
import {Subheader} from 'shells/typography';
import {Link} from 'shells/link';
import {ErrorMessage} from 'shells/form';

import './verify-sender-page.css';

export class VerifySenderPage extends React.PureComponent<ContextRouter> {
    render() {
        const {location} = this.props;

        let title = 'Success!';
        let message = 'You can now send email outreach through Nutshell.';
        if (location.pathname.endsWith('/failure')) {
            title = 'Uh Oh!';
            message = (
                <ErrorMessage>
                    Something went wrong, please try again or contact support!
                </ErrorMessage>
            );
        }

        return (
            <PreAuthLayout
                backgroundColor={colors.navyLt}
                imageLeft={<img alt='' src='/include/images/auth/confirm-email.svg' />}
            >
                <Helmet>
                    <title>{title}</title>
                </Helmet>

                <div>
                    <Subheader as='p'>{message}</Subheader>
                    <div styleName='back-to-nutshell-container'>
                        <Link href='/dashboard' variant='button-primary' size='xlarge'>
                            Take me back to Nutshell
                        </Link>
                    </div>
                </div>
            </PreAuthLayout>
        );
    }
}
