/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import {Motion, spring} from 'react-motion';

import {Percentage} from '../percentage';

import './progress-bar.css';

type Props = {
    percentComplete?: ?number,
    progressAmount?: ?number,
    height: number,
    color?: string,
    onAnimationComplete?: () => void,
    roundedCorners?: boolean,
    showPercentage?: boolean,
    backgroundColor?: string,
    tooltipText?: string,
};

export function ProgressBar(props: Props) {
    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    });

    // Clamp values between 0 and 1
    const clampedPercentComplete = Math.max(
        Math.min(props.percentComplete ? props.percentComplete : 0, 1),
        0
    );
    const barStyle = (interpolated) => ({
        width: `${interpolated.x * 100}%`,
        height: props.height,
        backgroundColor: props.color,
    });

    const containerStyle = {
        width: '100%',
        borderRadius: props.roundedCorners ? 3 : undefined,
        backgroundColor: props.backgroundColor,
    };

    return (
        <div
            className='flex align-center'
            data-tip={props.tooltipText || undefined}
            data-place={props.tooltipText ? 'bottom' : undefined}
        >
            <div styleName='bar' style={containerStyle}>
                <Motion
                    style={{x: spring(clampedPercentComplete)}}
                    onRest={props.onAnimationComplete}
                >
                    {(interpolatedStyle) => (
                        <div styleName='indicator' style={barStyle(interpolatedStyle)} />
                    )}
                </Motion>
            </div>
            {props.showPercentage ? (
                <div styleName='percentage-container'>
                    <Percentage value={props.percentComplete} />
                </div>
            ) : (
                undefined
            )}
            {typeof props.progressAmount === 'number' ? (
                <div styleName='progress-container'>{props.progressAmount}</div>
            ) : (
                undefined
            )}
        </div>
    );
}

ProgressBar.defaultProps = {
    height: 2,
};
