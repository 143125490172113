/* @flow */
import * as React from 'react';
import classnames from 'classnames';
import './badge.css';

type Props = {
    contents?: React.Node,
    color: 'grey' | 'blue',
    hideBadgeWhenEmpty?: boolean,
    onClick?: (SyntheticEvent<>) => void,
};
type State = {
    firstLoad: boolean,
};

/**
This is intended to be a generic UI element that can be added to
another component to indicate the presence of notifications (although
we don't use it for that purpose so far), or the presense of applied
filters (what we _do_ use it for currently).

It should be added within a container with a `position: relative`
style, because it uses absolute positioning to appear in the top-right
corner on top of the container.
 */
export class Badge extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            firstLoad: true,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.contents !== this.props.contents) {
            this.setState({firstLoad: false});
        }
    }

    static defaultProps = {
        color: 'grey',
    };

    render() {
        const containerStyleName = classnames(
            {
                container: this.props.contents,
                'container--small':
                    !this.props.contents && !this.props.hideBadgeWhenEmpty && !this.state.firstLoad,
                'container--small-initial':
                    !this.props.contents && !this.props.hideBadgeWhenEmpty && this.state.firstLoad,
                'container--hidden': !this.props.contents && this.props.hideBadgeWhenEmpty,
                clickable: this.props.onClick,
            },
            this.props.color
        );

        return (
            <div styleName={containerStyleName} onClick={this.props.onClick}>
                <span styleName='contents'>{this.props.contents}</span>
            </div>
        );
    }
}
