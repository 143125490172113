/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {FormField} from 'shells/form';
import {TextField} from 'shells/textfield';

type OwnProps = {|
    title?: string,
    placeholder: string,
    helperText: string,
|};

type Props = {...$Exact<FieldProps>, ...OwnProps};

export function TinyIntroTextField({title, helperText, input, meta, ...restProps}: Props) {
    return (
        <FormField
            innerId={input.name}
            label={title}
            helperText={helperText}
            errorMessage={meta.error && meta.submitFailed ? meta.error : null}
        >
            <TextField
                {...input}
                {...restProps}
                id={input.name}
                hasError={meta.error && meta.submitFailed}
                size='big'
            />
        </FormField>
    );
}
