/* @flow */

import * as React from 'react';
import type {Method} from './types';
import {SelectMethodPage} from './page/select-method-page';
import {VerifySMSPhonePage} from './page/verify-sms-phone-page';
import {VerifyCodePage} from './page/verify-code-page';
import {RecoveryCodesPage} from './page/recovery-codes-page';
import {DonePage} from './page/done-page';

type Steps = 'verifySMS' | 'verifyCode' | 'selectMethod' | 'recovery' | 'done';

export function MFASidebarController() {
    const [step, setStep] = React.useState<Steps>('selectMethod');
    const [selectedMethod, setSelectedMethod] = React.useState<Method>('sms');
    const [phoneNumber, setPhoneNumber] = React.useState('');

    if (step === 'selectMethod') {
        return (
            <SelectMethodPage
                goNext={() => {
                    if (selectedMethod === 'sms') {
                        setStep('verifySMS');
                    } else if (selectedMethod === 'app') {
                        setStep('verifyCode');
                    }
                }}
                setSelectedMethod={(method) => {
                    setSelectedMethod(method);
                }}
                selectedMethod={selectedMethod}
                resetPhoneNumber={() => {
                    setPhoneNumber('');
                }}
            />
        );
    } else if (step === 'verifySMS') {
        return (
            <VerifySMSPhonePage
                goNext={() => {
                    setStep('verifyCode');
                }}
                goBack={() => {
                    setStep('selectMethod');
                }}
                handlePhoneNumber={setPhoneNumber}
            />
        );
    } else if (step === 'verifyCode') {
        return (
            <VerifyCodePage
                goNext={() => {
                    setStep('recovery');
                }}
                goBack={() => {
                    setStep('verifySMS');
                }}
                method={selectedMethod}
                phoneNumber={phoneNumber}
            />
        );
    } else if (step === 'recovery') {
        return (
            <RecoveryCodesPage
                goNext={() => {
                    setStep('done');
                }}
                method={selectedMethod}
            />
        );
    } else if (step === 'done') {
        return <DonePage method={selectedMethod} />;
    } else {
        // Shouldn't happen, but components have to return something
        return null;
    }
}
