/* @flow */

import * as React from 'react';
import {Helmet} from 'react-helmet';
import {colors} from 'shells/colors';
import {headTitle} from 'shells/document';
import {Header, Subheader} from 'shells/typography';
import {PreAuthLayout} from 'shells/layout';
import {ErrorMessage} from 'shells/form';
import {Button} from 'shells/button';
import {LoadingIcon} from 'shells/icon';
import {Routing} from 'nutshell-core/routing';
import {Link} from 'shells/link';
import {formEncodedApi} from 'nutshell-core/api';
import type {HttpErrorObject} from 'nutshell-core/utils/errors';

import './verify-email-page.css';

const ERROR_DOMAIN_CANNOT_PIN = 'Domain cannot be added to this instance';

type State = {
    isLoading: boolean,
    cannotAddDomainError: boolean,
    hasResent: boolean,
    emailConfirmed: boolean,
    canPinDomain: boolean,
    emailAddress: ?string,
    domain: ?string,
    requestErrorMessage: ?string,
};

type Props = {};

export class VerifyEmailPage extends React.PureComponent<Props, State> {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            cannotAddDomainError: false,
            hasResent: false,
            emailConfirmed: false,
            canPinDomain: false,
            emailAddress: null,
            domain: null,
            requestErrorMessage: null,
        };
    }

    componentDidMount() {
        const urlParamString = window.location.search.slice(1); // Remove "?"
        const urlParams = Routing.deparam(urlParamString);
        const token = urlParams.token ? urlParams.token : '';

        this.verifyEmailDetails(token)
            .then((res) => {
                this.setState({
                    emailConfirmed: res.emailConfirmed,
                    canPinDomain: res.canPinDomain,
                    emailAddress: res.emailAddress,
                    domain: res.domain,
                    isLoading: false,
                    requestErrorMessage: null,
                });
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                    requestErrorMessage:
                        'Something went wrong confirming your email address. Please refresh the page to try again.',
                });
            });
    }

    render() {
        return (
            <PreAuthLayout
                backgroundColor={colors.navyLt}
                imageLeft={<img alt='' src='/include/images/auth/confirm-email.svg' />}
            >
                {this.state.isLoading ? (
                    <div styleName='loading-icon'>
                        <LoadingIcon />
                    </div>
                ) : (
                    undefined
                )}

                {this.state.requestErrorMessage ? (
                    <div>
                        <Subheader as='p'>
                            <ErrorMessage>{this.state.requestErrorMessage}</ErrorMessage>
                        </Subheader>
                        {this.renderBackToNutshellButton()}
                    </div>
                ) : (
                    undefined
                )}
                {!this.state.isLoading && !this.state.requestErrorMessage ? (
                    <div styleName='verify-email'>
                        {!this.state.emailConfirmed && !this.state.hasResent ? (
                            <div>
                                <Helmet>
                                    <title>{headTitle('Expired link')}</title>
                                </Helmet>
                                <Subheader as='p'>
                                    The link you used to verify your email address{' '}
                                    <strong>{this.state.emailAddress}</strong> has expired.
                                </Subheader>
                                <div styleName='resend-link'>
                                    <Link
                                        size='normal'
                                        variant='primary'
                                        onClick={this.handleResendVerifyEmail}
                                    >
                                        Click here to receive another email
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {this.state.emailConfirmed ? (
                                    <div>
                                        <Helmet>
                                            <title>{headTitle('Email verified')}</title>
                                        </Helmet>
                                        <Header as='h1'>
                                            Great!{' '}
                                            {this.state.emailAddress ? (
                                                <strong>{this.state.emailAddress}</strong>
                                            ) : (
                                                'Your email address'
                                            )}{' '}
                                            has been verified.
                                        </Header>
                                        <div>
                                            {this.state.canPinDomain &&
                                            !this.state.cannotAddDomainError ? (
                                                <div>
                                                    <Subheader as='p'>
                                                        Do you want your teammates{' '}
                                                        <strong>@{this.state.domain}</strong> to
                                                        share this trial with you?
                                                    </Subheader>
                                                    <Subheader as='p'>
                                                        When other people{' '}
                                                        <strong>@{this.state.domain}</strong> sign
                                                        up for Nutshell, we can connect them to this
                                                        account. We’ll always ask you for approval
                                                        before somebody joins.
                                                    </Subheader>
                                                    <div styleName='pin-domain-options'>
                                                        <Button
                                                            onClick={this.handleSubmit}
                                                            variant='primary'
                                                            size='xlarge'
                                                            isFullWidth={true}
                                                        >
                                                            Yes, others @{this.state.domain} will
                                                            share this account
                                                        </Button>
                                                        <Link
                                                            href='https://support.nutshell.com/'
                                                            variant='primary'
                                                        >
                                                            No, we will make separate accounts.
                                                        </Link>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {!this.state.cannotAddDomainError
                                                        ? this.renderBackToNutshellButton()
                                                        : undefined}
                                                </div>
                                            )}
                                            {this.state.cannotAddDomainError ? (
                                                <div>
                                                    <Subheader as='p'>
                                                        Oh no! We had a problem adding{' '}
                                                        <strong>@{this.state.domain}</strong> to
                                                        your account.{' '}
                                                    </Subheader>
                                                    <Subheader as='p'>
                                                        <Link
                                                            href='https://support.nutshell.com/'
                                                            variant='primary'
                                                            size='big'
                                                        >
                                                            Contact Nutshell Support
                                                        </Link>{' '}
                                                        for more information about what went wrong.
                                                    </Subheader>
                                                    {this.renderBackToNutshellButton()}
                                                </div>
                                            ) : (
                                                undefined
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <Helmet>
                                            <title>{headTitle('Confirmation re-sent')}</title>
                                        </Helmet>
                                        <Subheader as='p'>
                                            Great! We’ve re-sent a confirmation email to{' '}
                                            <strong>{this.state.emailAddress}</strong>
                                        </Subheader>
                                        {this.renderBackToNutshellButton()}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    undefined
                )}
            </PreAuthLayout>
        );
    }

    handleSubmit = (): Promise<*> => {
        return formEncodedApi
            .post('/my-account/add-domain.json', {emailAddress: this.state.emailAddress})
            .then(() => {
                // Take 'em to the dashboard
                window.location.href = '/dashboard';
            })
            .catch((err: HttpErrorObject) => {
                if (err.message === ERROR_DOMAIN_CANNOT_PIN) {
                    this.setState({cannotAddDomainError: true});
                }
            });
    };

    handleResendVerifyEmail = (): Promise<*> => {
        return formEncodedApi
            .post('/my-account/resend-verify-email.json', {
                emailAddress: this.state.emailAddress ? this.state.emailAddress : null,
            })
            .then((res) => {
                this.setState({hasResent: true});
                res.json();
            });
    };

    verifyEmailDetails = (token: string) => {
        // We need to know what email address to verify, get it from the URL
        const urlParamString = window.location.search.slice(1); // Remove "?"
        const urlParams = Routing.deparam(urlParamString);
        const emailAddress = urlParams.emailAddress ? urlParams.emailAddress : '';

        return formEncodedApi
            .get(
                `/my-account/verify-email-details.json?token=${token}&emailAddress=${encodeURIComponent(
                    emailAddress
                )}`
            )
            .then((response) => response.json());
    };

    renderBackToNutshellButton = () => {
        return (
            <div styleName='back-to-nutshell-container'>
                <Link href='/dashboard' variant='button-primary' size='xlarge'>
                    Take me back to Nutshell
                </Link>
            </div>
        );
    };
}
