/* @flow */

import * as React from 'react';
import {Helmet} from 'react-helmet';
import {headTitle} from 'shells/document';
import {Header, Subheader} from 'shells/typography';
import {PreAuthLayout} from 'shells/layout';
import {Link} from 'shells/link';
import {colors} from 'shells/colors';

export function ExpiredPage() {
    return (
        <PreAuthLayout
            backgroundColor={colors.navyLt}
            imageLeft={<img alt='' src='/include/images/auth/expired.svg' />}
        >
            <Helmet>
                <title>{headTitle('Trial expired')}</title>
            </Helmet>
            <div>
                <Header>We just hate goodbyes. 😭</Header>
                <Subheader as='p'>
                    Your Nutshell trial has expired, but we’d love for you to stick around! Want to
                    make our CRM part of your daily life? Please have an administrator log in and
                    purchase a{' '}
                    <Link href='https://www.nutshell.com/pricing/' variant='primary' size='big'>
                        plan
                    </Link>
                    .
                </Subheader>
                <Subheader as='p'>
                    <Link href='https://support.nutshell.com/' variant='primary' size='big'>
                        Contact Nutshell support
                    </Link>{' '}
                    for additional billing options or for information on an extended trial period.
                </Subheader>
            </div>
        </PreAuthLayout>
    );
}
