/* @flow */

import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {PreAuthLayout} from 'shells/layout';
import {Routing} from 'nutshell-core/routing';
import {formEncodedApi} from 'nutshell-core/api';
import type {HttpErrorObject} from 'nutshell-core/utils/errors';
import {colors} from 'shells/colors';

import {readLocalStorage} from '../../../util/localStorage';
import type {NutshellSignupResponse, EmailSignupFormValues} from '../types';
import {
    handleGoogleToken,
    handleMicrosoftToken,
    handleEmailSignup,
    trackGoogleLoginError,
    getMicrosoftOauthUrl,
} from '../utils';
import {CloudsBackgroundLeft} from '../clouds-background-left';
import {SignupForm} from './signup-form';

const ERROR_DOMAIN_RESERVED_MESSAGE = 'domain_reserved';
const ERROR_EMAIL_IN_USE_MESSAGE = 'email_in_use';
const ERROR_TOO_MANY_REQUESTS_MESSAGE = 'too_many_requests';

const USER_ERROR_MESSAGES = {
    [ERROR_TOO_MANY_REQUESTS_MESSAGE]:
        'Whoa! That’s a lot of sign-ups. Please wait a few minutes or disable your VPN before trying again.',
};

type RedirectParams = {
    pathname: string,
    search?: string,
    signupParams?: {
        name: string,
        email: string,
    },
};

type State = {
    nutshellSignupResponse: ?NutshellSignupResponse,
    redirectParams: ?RedirectParams,
    googleErrorMessage?: string,
    isLoggingInWithGoogle: boolean,
    microsoftOauthUrl: ?string,
    microsoftErrorMessage?: string,
    isLoggingInWithMicrosoft: boolean,
};

type Props = {};

export class SignupPage extends React.PureComponent<Props, State> {
    constructor() {
        super();
        this.state = {
            nutshellSignupResponse: null,
            redirectParams: null,
            isLoggingInWithGoogle: false,
            microsoftOauthUrl: getMicrosoftOauthUrl(),
            isLoggingInWithMicrosoft: false,
        };
    }

    componentDidMount() {
        // Track UTM params for signups that come right from referral sites like Capterra
        if (typeof Nutmeg !== 'undefined') {
            Nutmeg.trackUrl(); // eslint-disable-line no-undef
        }

        // if we loaded /signup?googleCredential=... then we're coming from a google signup and we immediately submit the JWT
        const urlParamString = window.location.search.slice(1); // Remove "?"
        const urlParams = Routing.deparam(urlParamString);
        // not sure why eslint wants === but it's a URL param so it's a string
        // eslint-disable-next-line eqeqeq
        if (urlParams.googleJwtSaved && urlParams.googleJwtSaved == 1) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({isLoggingInWithGoogle: true});
            this.handleOauthSignupSubmission('', 'google');
        }
    }

    render() {
        if (this.state.nutshellSignupResponse && this.state.nutshellSignupResponse.signupToken) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: '/welcome/company',
                        state: {
                            ...this.state.nutshellSignupResponse,
                        },
                    }}
                />
            );
        }

        if (this.state.redirectParams) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.redirectParams.pathname,
                        search: this.state.redirectParams.search,
                        state: this.state.redirectParams.signupParams,
                    }}
                />
            );
        }

        // Check if we're coming from an external signup form,
        // and already have a name/email address in the url params
        const urlParamString = window.location.search.slice(1); // Remove "?"
        const urlParams = Routing.deparam(urlParamString);

        return (
            <PreAuthLayout
                logoColor={colors.orange}
                backgroundColor={colors.tinyIntroSignup}
                imageLeft={
                    <CloudsBackgroundLeft>
                        <img src='/include/images/auth/login.svg' alt='user logging in' />
                    </CloudsBackgroundLeft>
                }
            >
                <Helmet>
                    <title>Nutshell | Sign up for a free trial</title>
                </Helmet>
                <SignupForm
                    onEmailSignup={this.handleEmailSignupSubmission}
                    onGoogleSignup={(token) => {
                        return new Promise(() => {
                            return this.handleOauthSignupSubmission(token, 'google');
                        });
                    }}
                    googleErrorMessage={this.state.googleErrorMessage}
                    onGoogleIdentityFailure={() => {
                        trackGoogleLoginError();

                        this.setState({
                            googleErrorMessage:
                                'Unknown error connecting to Google. Please try again.',
                        });
                    }}
                    initialValues={{
                        firstAndLastName: urlParams.name,
                        email: urlParams.email,
                    }}
                    isLoggingInWithGoogle={this.state.isLoggingInWithGoogle}
                    onMicrosoftSignup={(token: string) => {
                        return new Promise(() => {
                            return this.handleOauthSignupSubmission(token, 'microsoft');
                        });
                    }}
                    microsoftErrorMessage={this.state.microsoftErrorMessage}
                    onMicrosoftIdentityFailure={(error) => {
                        this.setState({
                            microsoftErrorMessage:
                                error && error.message
                                    ? error.message
                                    : 'Unknown error connecting to Microsoft. Please try again.',
                        });
                    }}
                    isLoggingInWithMicrosoft={this.state.isLoggingInWithMicrosoft}
                    microsoftOauthUrl={this.state.microsoftOauthUrl}
                />
            </PreAuthLayout>
        );
    }

    handleEmailSignupSubmission = (formValues: EmailSignupFormValues): Promise<*> => {
        return (
            handleEmailSignup({
                name: formValues.firstAndLastName,
                email: formValues.email,
                password: formValues.password,
                nutmeg: getNutmegParams(),
            })
                .then((response) => response.json())
                // Assuming that's successful, our backend will have
                // kicked off an instance provision, and passed us back
                // a signup token associated with that provisioning.
                // We need to store this so we can poll for that instance's
                // provisioning status.
                .then((nutResponse: NutshellSignupResponse) => {
                    this.setState({
                        nutshellSignupResponse: nutResponse,
                    });

                    return nutResponse;
                })
                .catch((err: HttpErrorObject) => {
                    if (err.message === ERROR_DOMAIN_RESERVED_MESSAGE) {
                        this.setState({
                            redirectParams: {
                                pathname: '/auth/join-your-team',
                                signupParams: {
                                    name: formValues.firstAndLastName,
                                    email: formValues.email,
                                },
                            },
                        });
                    } else if (err.message === ERROR_EMAIL_IN_USE_MESSAGE) {
                        this.setState({
                            redirectParams: {
                                pathname: '/auth',
                                search: `email=${encodeURIComponent(formValues.email)}`,
                            },
                        });
                    } else if (err.message && USER_ERROR_MESSAGES[err.message]) {
                        const userError = {
                            ...err,
                            message: USER_ERROR_MESSAGES[err.message],
                        };

                        throw userError;
                    } else {
                        throw err;
                    }
                })
        );
    };

    // User has selected their oauth identity, and we get back
    // a response from the identity provider with a token that we then
    // need to pass to the Nutshell backend to validate.
    handleOauthSignupSubmission = (token: string, service: 'microsoft' | 'google'): Promise<*> => {
        const tokenHandler = service === 'microsoft' ? handleMicrosoftToken : handleGoogleToken;
        const strategy = service === 'microsoft' ? 'o365' : 'google';

        // Validate oauth token on our backend
        return (
            tokenHandler({token: {id_token: token}, nutmeg: getNutmegParams()})
                .then((response) => response.json())
                // Assuming that's successful, our backend will have
                // kicked off an instance provision, and passed us back
                // a signup token associated with that provisioning.
                // We need to store this so we can poll for that instance's
                // provisioning status.
                .then((nutResponse: NutshellSignupResponse) => {
                    this.setState({
                        nutshellSignupResponse: nutResponse,
                    });

                    return nutResponse;
                })
                .catch((err: HttpErrorObject) => {
                    // The user's email and name are decrypted on the backend if authentication is successful and
                    // passed as headers in the error response
                    const email = err.httpResponse.headers.get('email');
                    const name = err.httpResponse.headers.get('name');
                    if (err.message === ERROR_DOMAIN_RESERVED_MESSAGE && name && email) {
                        this.setState({
                            redirectParams: {
                                pathname: '/auth/join-your-team',
                                signupParams: {
                                    name,
                                    email,
                                },
                            },
                        });
                    } else if (err.message === ERROR_EMAIL_IN_USE_MESSAGE && email) {
                        const newState =
                            service === 'microsoft'
                                ? {
                                      isLoggingInWithMicrosoft: true,
                                  }
                                : {isLoggingInWithGoogle: true};
                        // Set the state so we keep the loading icon on the Oauth
                        // button, and display a message telling the user we are
                        // logging them in.
                        this.setState(newState);
                        // When signing up with Oauth, if that email already
                        // exists as a Nutshell account, we'll just automatically
                        // try to log them in, since they've already verified
                        // their identity
                        formEncodedApi
                            .post('/auth', {
                                username: email,
                                password: token,
                                strategy,
                            })
                            .then((response: {url: string}) => {
                                window.location.href = response.url;
                            })
                            .catch(() => {
                                window.location.href = '/auth';
                            });
                    } else {
                        const newState =
                            service === 'microsoft'
                                ? {
                                      microsoftErrorMessage: err.message,
                                  }
                                : {googleErrorMessage: err.message};
                        this.setState(newState);
                    }
                })
        );
    };
}

function getNutmegParams() {
    const nutmeg = readLocalStorage('nutmeg');
    if (!nutmeg) {
        return null;
    }

    return JSON.parse(nutmeg);
}
