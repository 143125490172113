/* @flow */

/*
 * Returns standarized (non-DST) timezone offset, in minutes.
 *
 * Copied from public/include/modules/login.js
 */
export function getStandardTimezoneOffset() {
    const year = new Date().getFullYear();
    const jan = new Date(year, 0, 1);
    const jul = new Date(year, 6, 1);

    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}
