/* @flow */

import * as React from 'react';
import {type FieldProps} from 'redux-form';
import {Badge} from 'shells/badge';
import {CloseIcon} from 'shells/icon';
import './tiny-intro-company-logo.css';

type Props = FieldProps & {
    onRemove: () => void,
};

export function TinyIntroCompanyLogo(props: Props) {
    return (
        <div styleName='company-logo'>
            <img src={props.input.value} {...props.input} id={props.input.name} />
            <Badge
                contents={<CloseIcon size={8} fill='#fff' wrapWithDiv={false} />}
                onClick={props.onRemove}
                color='grey'
            />
        </div>
    );
}
