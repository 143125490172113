/* @flow */

/*
 * This function can be used after making a request to the server which might return validation
 * errors.  If it finds any, it will throw a validation error which can be caught and handled in the client code.
 */
export function validationErrorCheck(response: Response) {
    // $FlowFixMe upgrading Flow to v0.92.1
    return new Promise((resolve, reject) => {
        // A response can only be read once, so we'll clone it before reading
        const httpResponse = response.clone();
        httpResponse
            .json()
            .then((json) => {
                if (json.isValidationResult && !json.isValid) {
                    reject(new ValidationError(json));
                } else {
                    resolve(response);
                }
            })
            .catch(() => {
                // ignore
            });
    });
}

/**
 * An Error object for server-side validation errors.
 *
 * @param {Response} validationResult - The validation response json from the server
 * @param {string} [message] - An optional message string for the error object.
 * @return {void}
 */
export function ValidationError(validationResult: Object, message: string = 'Validation Error') {
    this.name = 'ValidationError';
    this.message = message;
    this.stack = new Error().stack;
    this.validationResult = validationResult;
}
ValidationError.prototype = Object.create(Error.prototype);
ValidationError.prototype.constructor = ValidationError;
