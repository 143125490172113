/* @flow */

import * as React from 'react';

import {LoadingIcon} from 'shells/icon';
import {useResizeObserver} from 'shells/utils/hooks';
import {colors} from 'shells/colors';

import MicrosoftSquareLogo from 'shells/logos/microsoft-square.svg';
import {openOauthWindow} from '../../../oauth/open-oauth-window';

import './microsoft-oauth-button.css';

const MAX_WIDTH = 400;

type Props = {|
    variant: 'login' | 'signup',
    oauthUrl: ?string,
    onAuthSuccess: (string) => void,
    onAuthFailure: (?Error) => void,
    isSubmittingAuthResult: boolean,
|};

export function MicrosoftOauthButton(props: Props) {
    const [buttonWidth, setButtonWidth] = React.useState<number>(MAX_WIDTH);

    const buttonRef = React.useRef(undefined);

    const [width] = useResizeObserver(
        buttonRef && buttonRef.current ? buttonRef.current : undefined
    );

    React.useEffect(() => {
        if (width && width < MAX_WIDTH) {
            setButtonWidth(width);
        }
    }, [width]);

    const copy = `${props.variant === 'login' ? 'Sign in' : 'Continue'} with Microsoft`;

    return (
        <button
            type='button'
            ref={buttonRef}
            styleName='microsoft-oauth-button'
            disabled={props.isSubmittingAuthResult}
            width={buttonWidth}
            onClick={(event) => {
                event.preventDefault();

                if (!props.oauthUrl) {
                    props.onAuthFailure(new Error('Cant login with Microsoft. Try again later'));

                    return;
                }

                openOauthWindow(
                    props.oauthUrl,
                    copy,
                    {
                        toolbar: 'no',
                        location: 'no',
                        width: 600,
                        height: 850,
                    },
                    true
                )
                    .then((response) => {
                        if (response) {
                            props.onAuthSuccess(response);
                        }
                    })
                    .catch((error) => {
                        props.onAuthFailure && props.onAuthFailure(error);
                    });
            }}
        >
            <MicrosoftSquareLogo />
            {props.isSubmittingAuthResult ? (
                <>
                    <span>
                        {props.variant === 'login' ? 'Signing in' : 'Signing up'} with Microsoft…
                    </span>
                    <LoadingIcon size={29} fill={colors.offWhiteDk} />
                </>
            ) : (
                <span>{copy}</span>
            )}
        </button>
    );
}
