/* @flow */

import * as React from 'react';

import {GoogleOAuthProvider, GoogleLogin, type CredentialResponse} from '@react-oauth/google';

import {LoadingIcon} from 'shells/icon';
import {useResizeObserver} from 'shells/utils/hooks';
import {colors} from 'shells/colors';
import {Card} from 'shells/card';

import {getGoogleAppClientId} from '../utils';

import './google-oauth-button.css';

const MAX_WIDTH = 400;
export const GOOGLE_OAUTH_BUTTON_ID = 'google-oauth-button';

type Props = {|
    variant: 'login' | 'signup',
    onGoogleOAuthSuccess: (token: string) => void,
    onGoogleOAuthFailure: () => void,
    showLoadingSpinner: boolean,
|};

function LoginButtonComponent(props: Props) {
    const [buttonWidth, setButtonWidth] = React.useState<number>(MAX_WIDTH);

    const buttonRef = React.useRef(undefined);

    const [width] = useResizeObserver(
        buttonRef && buttonRef.current ? buttonRef.current : undefined
    );

    React.useEffect(() => {
        if (width && width < MAX_WIDTH) {
            setButtonWidth(width);
        }
    }, [width]);

    return (
        <div ref={buttonRef} styleName='google-oauth-button-container'>
            {props.showLoadingSpinner ? (
                <Card hasBorder={true}>
                    <div styleName='loading-placeholder'>
                        <div>
                            {props.variant === 'login' ? 'Logging in' : 'Signing up'} with Google…
                        </div>
                        <LoadingIcon size={29} fill={colors.offWhiteDk} />
                    </div>
                </Card>
            ) : (
                <GoogleLogin
                    id={GOOGLE_OAUTH_BUTTON_ID}
                    onSuccess={(response: CredentialResponse) => {
                        props.onGoogleOAuthSuccess(response.credential);
                    }}
                    onError={props.onGoogleOAuthFailure}
                    width={buttonWidth}
                    text={props.variant === 'login' ? 'signin_with' : 'continue_with'}
                />
            )}
        </div>
    );
}

export function GoogleOAuthButton(props: Props) {
    return (
        <GoogleOAuthProvider clientId={getGoogleAppClientId()}>
            <LoginButtonComponent {...props} />
        </GoogleOAuthProvider>
    );
}
