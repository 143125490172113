/* @flow */

import * as React from 'react';
import {Helmet} from 'react-helmet';
import {headTitle} from 'shells/document';
import {Header, Subheader} from 'shells/typography';
import {PreAuthLayout} from 'shells/layout';
import {Link} from 'shells/link';
import {colors} from 'shells/colors';

export function PausedPage() {
    return (
        <PreAuthLayout
            backgroundColor={colors.navyLt}
            imageLeft={<img alt='' src='/include/images/auth/expired.svg' />}
        >
            <Helmet>
                <title>{headTitle('Account paused')}</title>
            </Helmet>
            <div>
                <Header>We hope to see you soon</Header>
                <Subheader as='p'>
                    An administrator for your account has paused your subscription. Ready to pick
                    things up where you left off? Please have an administrator log in and restore
                    your account.
                </Subheader>
                <Subheader as='p'>
                    <Link href='https://support.nutshell.com/' variant='primary' size='big'>
                        Contact Nutshell support
                    </Link>{' '}
                    with any questions.
                </Subheader>
            </div>
        </PreAuthLayout>
    );
}
