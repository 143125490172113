/* @flow */

import * as React from 'react';
import {reduxForm} from 'redux-form';
import type {FormProps} from 'redux-form/lib/types.js.flow';
import {Header, Subheader} from 'shells/typography';
import {Button} from 'shells/button';
import {Link} from 'shells/link';

import {TINY_INTRO_REDUX_FORM} from '../../constants';

import './final-slide.css';

type OwnProps = {|
    isProvisioningComplete: boolean,
    accountProvisionedErrorMessage: ?string,
|};

type Props = {...OwnProps, ...$Exact<FormProps>};

export class FinalSlideComponent extends React.Component<Props> {
    render() {
        return (
            <div styleName='final-slide'>
                {this.props.accountProvisionedErrorMessage ? (
                    <div>
                        <Subheader>
                            Something went wrong setting up your Nutshell account.
                        </Subheader>
                        <Subheader as='p'>
                            This shouldn’t happen! Please contact our support team at{' '}
                            <Link href='/mailto:support@nutshell.com' variant='primary' size='big'>
                                support@nutshell.com
                            </Link>
                            , or give us a call at 888-336-8808.
                        </Subheader>
                    </div>
                ) : (
                    <React.Fragment>
                        <Header>
                            {this.props.isProvisioningComplete
                                ? 'Here we go!'
                                : 'Give us a moment…'}
                        </Header>
                        <Subheader>
                            {this.props.isProvisioningComplete
                                ? 'Head on into Nutshell to finish setting up'
                                : 'Getting some last things together'}
                        </Subheader>
                        <form onSubmit={this.props.handleSubmit}>
                            <Button
                                type='submit'
                                disabled={!this.props.isProvisioningComplete}
                                variant='primary'
                                size='xlarge'
                                isFullWidth={true}
                            >
                                {this.props.isProvisioningComplete
                                    ? 'Let’s get started!'
                                    : 'Finishing up…'}
                            </Button>
                        </form>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export const FinalSlide = reduxForm({
    form: TINY_INTRO_REDUX_FORM,
    destroyOnUnmount: false,
})(FinalSlideComponent);
