/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {SelectPickerGeneric} from 'shells/select-picker-generic';
import {FormField} from 'shells/form';

type Option = {value: string, label: string};

type Props = {
    ...FieldProps,
    title: string,
    options: Option[],
    isRequired?: boolean,
    includeOther?: boolean,
};

export function TinyIntroSelectPicker(props: Props) {
    const {input, ...restProps} = props;

    const options = props.includeOther
        ? props.options.concat({value: 'other', label: 'Other'})
        : props.options;

    const value = options.find((field) => field.value === props.input.value);

    return (
        <FormField
            innerId={props.input.name}
            label={props.title}
            errorMessage={props.meta.error && props.meta.submitFailed ? props.meta.error : null}
            isRequired={props.isRequired}
        >
            {/* $FlowIgnore I can't get this to work because of the exact prop types */}
            <SelectPickerGeneric
                {...input}
                {...restProps}
                onBlur={() => {
                    // Who can explain it, who can tell why. Somehow, this
                    // works. If the params are passed through, the value for
                    // this field is reset on blur :shrug:
                    input.onBlur();
                }}
                onChange={(selection: Option) => {
                    input.onChange(selection.value);
                }}
                value={value}
                options={options}
            />
        </FormField>
    );
}
