/* @flow */

import * as React from 'react';
import './clouds-background-left.css';

type Props = {
    children: React.Node,
};

export function CloudsBackgroundLeft(props: Props) {
    return <div styleName='clouds-background-left'>{props.children}</div>;
}
