/* @flow */

import * as React from 'react';
import {Button} from 'shells/button';
import {ErrorMessage} from 'shells/form';
import {TextField} from 'shells/textfield';
import {Stack} from 'shells/layout';
import {MfaHeader, MfaBody} from '../typography/index';
import {regenerateMfa} from '../../../auth/utils';

type Props = {|
    goNext: () => void,
    handlePhoneNumber: (string) => void,
    prefilledPhoneNumber?: string,
|};

export function VerifySmsPhoneScreen(props: Props) {
    const [isSendingText, setIsSendingText] = React.useState<boolean>(false);
    const [codeIsInvalid, setCodeIsInvalid] = React.useState<string>('');
    const [phoneNumber, setPhoneNumber] = React.useState<string>(
        props.prefilledPhoneNumber !== undefined ? props.prefilledPhoneNumber : ''
    );

    const sendText = () => {
        if (phoneNumber !== '') {
            setIsSendingText(true);
            regenerateMfa({mfaType: '1', userData: phoneNumber})
                .then((result) => {
                    setIsSendingText(false);
                    if (!result.isValid) {
                        throw new Error(result.messages);
                    }

                    props.handlePhoneNumber(result.phoneNumber);
                    props.goNext();
                })
                .catch(() => {
                    setCodeIsInvalid('Invalid phone number');
                    setIsSendingText(false);
                });
        } else {
            setCodeIsInvalid('Phone number must not be blank');
        }
    };

    return (
        <Stack spacing={12}>
            <MfaHeader>Step 1: Enter your phone number</MfaHeader>
            <MfaBody>We’ll text you a code</MfaBody>

            <TextField
                id='phone'
                name='phone'
                size='big'
                placeholder='+1 888-336-8808'
                type='phone'
                value={phoneNumber}
                onEnter={sendText}
                onChange={(value) => {
                    setPhoneNumber(value);
                }}
            />
            <Button
                variant='primary'
                size='xlarge'
                disabled={isSendingText}
                isFullWidth={true}
                onClick={sendText}
            >
                {isSendingText ? 'Sending…' : 'Send text'}
            </Button>

            {codeIsInvalid !== '' ? <ErrorMessage>{codeIsInvalid}</ErrorMessage> : undefined}
        </Stack>
    );
}
