/* @flow */

import * as React from 'react';
import {Link} from 'shells/link';
import {NavButtons} from 'shells/modal/step-modal/layout';
import {MarketingHeader} from 'shells/typography';
import {VerifySmsPhoneScreen} from '../screens/verify-sms-phone-screen';

type Props = {|
    goNext: () => void,
    goBack: () => void,
    handlePhoneNumber: (string) => void,
    prefilledPhoneNumber?: string,
|};

export function VerifySMSPhonePage(props: Props) {
    return (
        <>
            <MarketingHeader>Verify method</MarketingHeader>
            <VerifySmsPhoneScreen
                goNext={() => {
                    props.goNext();
                }}
                handlePhoneNumber={props.handlePhoneNumber}
                prefilledPhoneNumber={props.prefilledPhoneNumber}
            />

            <NavButtons style={{justifyContent: 'flex-start'}}>
                <Link variant='primary' size='big' onClick={props.goBack}>
                    Back
                </Link>
            </NavButtons>
        </>
    );
}
