/* @flow */

import * as React from 'react';
import {Field, reduxForm} from 'redux-form';
import type {FormProps} from 'redux-form/lib/types.js.flow';

import {Subheader} from 'shells/typography';
import {Button} from 'shells/button';
import {ErrorMessage} from 'shells/form';

import {FORGOT_PASSWORD_REDUX_FORM} from '../../constants';
import {TinyIntroTextField} from '../../fields/tiny-intro-text-field';

import './forgot-password-form.css';

type OwnProps = {|
    successMessage: ?string,
|};

type Props = {...OwnProps, ...$Exact<FormProps>};

function validate(values: Object) {
    const errors = {};

    if (!values.username || !values.username.trim()) {
        errors.username = 'Please enter an email address';
    }

    return errors;
}

export function ForgotPasswordFormComponent(props: Props) {
    React.useEffect(() => {
        props.destroy();
        props.initialize(props.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form onSubmit={props.handleSubmit}>
            <Subheader>
                Enter your email address and we’ll send a link to reset your password.
            </Subheader>
            <div styleName='fields'>
                <Field
                    key='email'
                    name='username'
                    placeholder='john.appleseed@nutshell.com'
                    title='Email'
                    type='email'
                    autoFocus={true}
                    disabled={props.submitSucceeded}
                    component={TinyIntroTextField}
                />
            </div>
            {props.successMessage ? (
                <div styleName='success'>{props.successMessage}</div>
            ) : (
                <div styleName='button submit'>
                    <Button
                        disabled={props.submitting}
                        type='submit'
                        size='xlarge'
                        isFullWidth={true}
                    >
                        Reset password
                    </Button>
                </div>
            )}

            {props.submitFailed ? <ErrorMessage>{props.error}</ErrorMessage> : null}
        </form>
    );
}

export const ForgotPasswordForm = reduxForm({
    form: FORGOT_PASSWORD_REDUX_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
})(ForgotPasswordFormComponent);
