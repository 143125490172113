/* @flow */

import * as React from 'react';
import {MarketingHeader} from 'shells/typography';
import {VerifyCodeScreen} from '../screens/verify-code-screen';
import type {Method} from '../types';

type Props = {|
    goNext: () => void,
    goBack: () => void,
    method: Method,
    phoneNumber: string,
|};

export function VerifyCodePage(props: Props) {
    return (
        <>
            <MarketingHeader>Verify method</MarketingHeader>
            <VerifyCodeScreen
                goNext={props.goNext}
                goBack={props.goBack}
                method={props.method}
                phoneNumber={props.phoneNumber}
            />
        </>
    );
}
