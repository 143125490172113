/* @flow */

import * as React from 'react';
import {type ContextRouter} from 'react-router-dom';
import {SubmissionError} from 'redux-form';
import {Helmet} from 'react-helmet';
import {formEncodedApi} from 'nutshell-core/api';
import {Routing} from 'nutshell-core/routing';

import {
    validationErrorCheck,
    ValidationError,
} from 'nutshell-core/validation/validation-error-check';

import {makeErrorObject} from 'nutshell-core/validation/make-error-object';
import {headTitle} from 'shells/document';
import {SetNewPasswordForm} from './set-new-password-form';

type OwnProps = {|
    // We'll show difficult copy accordingly
    isNewUser: boolean,
|};

type Props = {...OwnProps, ...ContextRouter};

export function SetNewPasswordPage(props: Props) {
    const params = Routing.deparam(props.location.search);
    const username = params ? params.username : '';
    const token = params ? params.token : '';

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {headTitle(props.isNewUser ? 'Welcome to Nutshell!' : 'Set a new password')}
                </title>
            </Helmet>
            <SetNewPasswordForm
                isNewUser={props.isNewUser}
                onSubmit={(data) =>
                    handleSubmit({
                        username: username,
                        token: token,
                        ...data,
                    })
                }
            />
        </React.Fragment>
    );
}

function handleSubmit(formData) {
    const csrfTokenInput = document.querySelector('input[name="nutshell-csrf-token"]');

    return formEncodedApi
        .post(
            '/auth/reset-password',
            {
                username: formData.username,
                token: formData.token,
                password: formData.password,
                passwordConfirm: formData.passwordConfirm,
                // $FlowIgnore this is gonna be a HTMLInputElement
                csrf: csrfTokenInput ? csrfTokenInput.value : '',
            },
            {redirect: 'follow'}
        )
        .then((res) => {
            if (res && res.redirected) {
                window.location.href = res.url;
            }

            return res;
        })
        .then(validationErrorCheck)
        .then((res) => res.json())
        .catch((err: Error) => {
            if (err instanceof ValidationError && err.validationResult) {
                throw new SubmissionError(makeErrorObject(err));
            }
        });
}
