/* @flow */

import * as React from 'react';
import {MfaRecoveryCodes} from 'shells/mfa/mfa-recovery-codes';
import {Stack} from 'shells/layout';
import {MfaBody} from '../typography/index';
import {getBackupCodes} from '../../../auth/utils';
import type {Method} from '../types';

import './recovery-codes.css';

type Props = {|
    onNextReady?: () => void,
    method: Method,
|};

export function ViewRecoveryCodesScreen(props: Props) {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [recoveryCodes, setRecoveryCodes] = React.useState([]);

    const {method} = props;

    React.useEffect(() => {
        setIsLoading(true);

        const fetchedCodes = [];

        getBackupCodes().then((result) => {
            result.backupCodes.forEach((code) => {
                fetchedCodes.push(code.code);
            });

            setRecoveryCodes(fetchedCodes);
            setIsLoading(false);
        });
    }, [method]);

    return (
        <>
            <Stack spacing={12}>
                <MfaBody>
                    <b>Save or download these recovery codes</b> to get back into your Nutshell
                    account if your phone gets lost or stolen.
                </MfaBody>

                <MfaBody>These can also be viewed or regenerated from settings anytime.</MfaBody>

                <div styleName='mfa-codes'>
                    <MfaRecoveryCodes
                        isLoading={isLoading}
                        codes={recoveryCodes}
                        onCodesSaved={props.onNextReady}
                        showRegenerateButton={false}
                    />
                </div>
            </Stack>
        </>
    );
}
