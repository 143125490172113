/* @flow */

import * as React from 'react';
import './mfa-body.css';

type Props = {
    children?: React.Node,
    as: string,
};

MfaBody.defaultProps = {
    as: 'div',
};

export function MfaBody({children, as: Tag}: Props) {
    return <Tag styleName='mfa-body'>{children}</Tag>;
}
