/* @flow */

import * as React from 'react';
import {Helmet} from 'react-helmet';

import {headTitle} from 'shells/document';
import {ResetSuccessForm} from './reset-success-form';

export function ResetSuccessPage() {
    return (
        <React.Fragment>
            <Helmet>
                <title>{headTitle('Reset password')}</title>
            </Helmet>
            <ResetSuccessForm />
        </React.Fragment>
    );
}
