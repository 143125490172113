/* @flow */

import * as React from 'react';
import {Redirect, type ContextRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {headTitle} from 'shells/document';
import {PreAuthLayout} from 'shells/layout';
import {colors} from 'shells/colors';

import {JoinYourTeam} from './join-your-team';

export class JoinYourTeamPage extends React.Component<ContextRouter> {
    render() {
        const {location} = this.props;

        return (
            <PreAuthLayout
                backgroundColor={colors.navyLt}
                imageLeft={<img alt='' src='/include/images/auth/team.svg' />}
            >
                <Helmet>
                    <title>{headTitle('Join your team')}</title>
                </Helmet>
                {location && location.state && location.state.email ? (
                    <JoinYourTeam email={location.state.email} name={location.state.name} />
                ) : (
                    <Redirect to={{path: '/signup'}} />
                )}
            </PreAuthLayout>
        );
    }
}
