/* @flow */

import * as React from 'react';
import {ButtonGroup} from 'shells/button-group';

import './layout.css';

type Props = {|
    children: React.Node,
|};

type ModalMainProps = {|
    ...Props,
    fullheight?: boolean,
|};

type LeftFrameProps = {|
    ...Props,
    title?: string,
|};

type NavButtonsProps = {|
    ...Props,
    style?: Object,
|};

type FooterWithControlsProps = {|
    controls: React.Node,
    buttons: React.Node,
|};

export function ModalMain(props: ModalMainProps) {
    return (
        <div styleName={props.fullheight ? 'modal-body--fullheight' : 'modal-body'}>
            {props.children}
        </div>
    );
}

export function RightFrame(props: Props) {
    return <div styleName='right-frame'>{props.children}</div>;
}

export function LeftFrame(props: LeftFrameProps) {
    return (
        <div styleName='left-frame'>
            <div styleName='step-header'>{props.title}</div>
            {props.children}
        </div>
    );
}

export function NavButtons(props: NavButtonsProps) {
    return (
        <div styleName='nav-buttons' style={props.style}>
            <ButtonGroup>{props.children}</ButtonGroup>
        </div>
    );
}

export function FooterWithControls(props: FooterWithControlsProps) {
    return (
        <div styleName='footer'>
            {props.controls}
            <ButtonGroup>{props.buttons}</ButtonGroup>
        </div>
    );
}
