/* @flow */

import {stringify} from 'qs';

type WindowFeatures = {
    height: number,
    width: number,
    left?: string | number,
    top?: string | number,
    menubar?: 'yes' | 'no',
    toolbar?: 'yes' | 'no',
    location?: 'yes' | 'no',
    personalbar?: 'yes' | 'no',
    status?: 'yes' | 'no',
    dialog?: 'yes' | 'no',
    alwaysOnTop?: 'yes' | 'no',
    alwaysRaised?: 'yes' | 'no',
};

export function openOauthWindow(
    url: string,
    windowName: string,
    options: WindowFeatures,
    expectingToken?: boolean
): Promise<?string> {
    const windowFeatures = {
        top: window.screenY + (window.outerHeight - options.height) / 2.5,
        left: window.screenX + (window.outerWidth - options.width) / 2,
        ...options,
    };
    const optionString = stringify(windowFeatures, {encode: false, delimiter: ','});
    const popupWindow = window.open(url, windowName, optionString);

    return expectingToken ? pollPopupForIdToken(popupWindow) : pollPopup(popupWindow);
}

function pollPopup(popupWindow): Promise<void> {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (popupWindow === null || popupWindow.closed || popupWindow.closed === undefined) {
                clearInterval(interval);
                resolve();
            }
        }, 500);
    });
}
/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/src/interaction_client/PopupClient.ts#L564
 */
function pollPopupForIdToken(popupWindow): Promise<?string> {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (popupWindow.closed) {
                clearInterval(interval);
                resolve();

                return;
            }
            let href = '';
            try {
                href = popupWindow.location.href;
            } catch {
                // ignore
            }

            if (!href || href === 'about:blank') {
                return;
            }

            clearInterval(interval);
            let idToken = '';

            if (popupWindow) {
                idToken = popupWindow.location.hash ? popupWindow.location.hash : '';
                idToken = idToken.replace('#', '');
                popupWindow.close();
            }

            resolve(idToken);
        }, 250);
    });
}
