/* @flow */

import * as React from 'react';
import {Link} from 'shells/link';
import {Header, Subheader} from 'shells/typography';
import {Button} from 'shells/button';
import {handleInvitationRequest} from '../utils';

import './join-your-team.css';

type Props = {
    email: string,
    name: string,
};

type State = {
    hasRequested: boolean,
    isRequesting: boolean,
};

export class JoinYourTeam extends React.Component<Props, State> {
    constructor() {
        super();
        this.state = {
            hasRequested: false,
            isRequesting: false,
        };
    }

    render() {
        const domain = this.props.email.split('@')[1];

        return (
            <React.Fragment>
                <div styleName='join-your-team'>
                    <Header>Good news! You’ve already got a team.</Header>
                    <Subheader as='p'>
                        <span>
                            It looks like <strong>@{domain}</strong> email addresses are already
                            associated with a Nutshell account. You’ll need to request an invitation
                            from your Nutshell admin to continue.
                        </span>
                    </Subheader>
                    <div>
                        {this.state.hasRequested ? (
                            <div styleName='success'>Request sent!</div>
                        ) : (
                            <Button
                                size='xlarge'
                                isFullWidth={true}
                                onClick={this.handleRequestInvite}
                                disabled={this.state.isRequesting}
                                variant='primary'
                            >
                                {this.state.isRequesting
                                    ? 'Sending request…'
                                    : 'Request an invite from your admin'}
                            </Button>
                        )}
                    </div>
                    {this.state.hasRequested || this.state.isRequesting ? (
                        undefined
                    ) : (
                        <div styleName='contact-support'>
                            Do you own {domain}?{' '}
                            <Link
                                variant='primary'
                                size='small'
                                href='https://support.nutshell.com'
                            >
                                Contact support
                            </Link>
                        </div>
                    )}
                </div>
                <div styleName='different-address-container'>
                    <Link variant='primary' href='/signup'>
                        Sign up with a different email address
                    </Link>
                </div>
            </React.Fragment>
        );
    }

    handleRequestInvite = () => {
        this.setState({isRequesting: true});

        return handleInvitationRequest({
            emailAddress: this.props.email,
            name: this.props.name,
        })
            .then((res) => {
                if (res && res.redirected) {
                    window.location.href = res.url;

                    return res;
                }

                this.setState({hasRequested: true, isRequesting: false});
            })
            .catch(() => {
                this.setState({hasRequested: true, isRequesting: false});
            });
    };
}
