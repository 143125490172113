/* @flow */

import * as React from 'react';
import {Switch, Route, type ContextRouter} from 'react-router-dom';
import {PreAuthLayout} from 'shells/layout';
import {colors} from 'shells/colors';

import {CloudsBackgroundLeft} from '../clouds-background-left';
import {LoginPage} from './login-page';
import {ForgotPasswordPage} from './forgot-password/forgot-password-page';
import {ResetSuccessPage} from './reset-success/reset-success-page';
import {SetNewPasswordPage} from './set-new-password/set-new-password-page';

/*
 * This is the top-level ui component for pages associated with the login flow
 */
export class LoginRoot extends React.Component<{}> {
    render() {
        return (
            <PreAuthLayout
                backgroundColor={colors.tinyIntroLogin}
                imageLeft={
                    <CloudsBackgroundLeft>
                        <img src='/include/images/auth/login.svg' />
                    </CloudsBackgroundLeft>
                }
            >
                <Switch>
                    <Route path='/auth/forgot-password' component={ForgotPasswordPage} />
                    <Route
                        path='/auth/reset-password'
                        render={(props: ContextRouter) => (
                            <SetNewPasswordPage {...props} isNewUser={false} />
                        )}
                    />
                    <Route path='/auth/reset-success' component={ResetSuccessPage} />
                    <Route
                        path='/auth/get-started'
                        render={(props: ContextRouter) => (
                            <SetNewPasswordPage {...props} isNewUser={true} />
                        )}
                    />
                    <Route path='/auth' exact={true} component={LoginPage} />
                </Switch>
            </PreAuthLayout>
        );
    }
}
