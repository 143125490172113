/* @flow */

import * as React from 'react';
import {type ContextRouter} from 'react-router-dom';
import {SubmissionError} from 'redux-form';
import {Helmet} from 'react-helmet';
import {formEncodedApi} from 'nutshell-core/api';
import {Routing} from 'nutshell-core/routing';
import {
    validationErrorCheck,
    ValidationError,
} from 'nutshell-core/validation/validation-error-check';
import {makeErrorObject} from 'nutshell-core/validation/make-error-object';
import {headTitle} from 'shells/document';
import {ForgotPasswordForm} from './forgot-password-form';

export function ForgotPasswordPage(props: ContextRouter) {
    const [successMsg, setSuccessMsg] = React.useState(null);
    const params = Routing.deparam(props.location.search);
    const username = params ? params.email : '';

    return (
        <React.Fragment>
            <Helmet>
                <title>{headTitle('Reset password')}</title>
            </Helmet>
            <ForgotPasswordForm
                successMessage={successMsg}
                initialValues={{username}}
                onSubmit={(data) =>
                    handleSubmit(data).then((res) => {
                        if (res && res.success) {
                            setSuccessMsg(res.message);
                        }
                    })
                }
            />
        </React.Fragment>
    );
}

function handleSubmit(formData) {
    return formEncodedApi
        .post(
            '/auth/forgot-password.json',
            {username: formData.username},
            {headers: {accept: 'application/json'}}
        )
        .then(validationErrorCheck)
        .then((res) => res.json())
        .catch((err: Error) => {
            if (err instanceof ValidationError && err.validationResult) {
                throw new SubmissionError(makeErrorObject(err));
            }
        });
}
