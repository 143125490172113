/* @flow */

import * as React from 'react';
import {MfaCode} from 'shells/mfa/mfa-code';
import {Button} from 'shells/button';
import {Link} from 'shells/link';
import {Stack} from 'shells/layout';
import {MfaHeader, MfaBody, MfaSecondary} from '../typography/index';
import {regenerateMfa, verifyMfaCode} from '../../../auth/utils';
import type {Method} from '../types';
import {TYPE_APP, TYPE_SMS} from '../constants';

import '../verify-code.css';

type Props = {|
    method: Method,
    goNext: () => void,
    goBack: () => void,
    phoneNumber: string,
|};

export function VerifyCodeScreen(props: Props) {
    const [qrCode, setQrCode] = React.useState('');
    const {method} = props;

    React.useEffect(() => {
        if (method === 'app') {
            if (qrCode === '') {
                regenerateMfa({mfaType: TYPE_APP}).then((result) => {
                    setQrCode(result.result);
                });
            }
        }
    }, [method, qrCode]);

    const handleVerifyCode = (code: string, type: string): Promise<*> => {
        return new Promise(function(resolve, reject) {
            verifyMfaCode({
                mfaCode: code,
                mfaType: type,
            }).then((result) => {
                result.json().then((innerResult) => {
                    if (innerResult.result) {
                        props.goNext();
                        resolve();
                    } else {
                        reject(innerResult);
                    }
                });
            });
        });
    };

    return (
        <>
            {props.method === 'app' && (
                <>
                    <Stack spacing={12}>
                        <MfaBody>
                            Make sure you have an app on your phone that will provide two-factor
                            authentication codes when prompted. Nutshell recommends{' '}
                            <Link
                                href='https://duo.com/'
                                target='_blank'
                                rel='noopener noreferrer'
                                variant='primary'
                                size='small'
                            >
                                Duo
                            </Link>
                            {' or '}
                            <Link
                                href='https://authy.com/'
                                target='_blank'
                                rel='noopener noreferrer'
                                variant='primary'
                                size='small'
                            >
                                Authy
                            </Link>
                            .
                        </MfaBody>

                        <MfaHeader>Step 1: Scan QR code</MfaHeader>

                        <MfaBody>Use your authenticator app to scan the QR code below:</MfaBody>

                        <div styleName='qr-container'>
                            <img styleName='qr-image' src={qrCode} />
                        </div>

                        <MfaHeader>Step 2: Enter code</MfaHeader>

                        <MfaBody>After scanning, enter the code displayed by your app:</MfaBody>

                        <MfaCode
                            onVerifyMfaCode={(code) => handleVerifyCode(code, TYPE_APP)}
                            inputCount={6}
                            mfaMethod={TYPE_APP}
                            styleName='mfa-code'
                        />
                    </Stack>
                </>
            )}

            {props.method === 'sms' && (
                <>
                    <Stack spacing={12}>
                        <MfaBody>Great! We texted your code to {props.phoneNumber}</MfaBody>

                        <MfaHeader>Step 2: Enter code</MfaHeader>

                        <MfaBody>
                            When you receive a code, enter it here (expires after five minutes)
                        </MfaBody>

                        <MfaCode
                            onVerifyMfaCode={(code) => handleVerifyCode(code, TYPE_SMS)}
                            inputCount={6}
                            mfaMethod={TYPE_SMS}
                            styleName='mfa-code'
                        />
                    </Stack>
                </>
            )}

            {props.method === 'sms' && (
                <MfaSecondary>
                    Didn’t receive a code?{' '}
                    <Button
                        variant='text-primary'
                        onClick={props.goBack}
                        styleName='send-again-button'
                    >
                        Try sending it again
                    </Button>
                </MfaSecondary>
            )}
        </>
    );
}
