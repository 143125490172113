/* @flow */

type Props = {
    /** Percentage value, 0-1 for 0%-100% */
    value: ?number,
    /** Number of significant digits to show */
    precision?: ?number,
};

Percentage.defaultProps = {
    precision: 2,
};

/** This takes a decimal number value, and converts it into a rounded percentage string */
export function Percentage({value, precision}: Props) {
    if (value === null || value === undefined || isNaN(value)) return '—%';

    const percent = value * 100;

    // Need to cast to a Number to avoid exponential notation
    // https://stackoverflow.com/a/4689230
    const rounded = precision ? Number(percent.toPrecision(precision)) : Math.round(percent);

    return `${rounded}%`;
}
