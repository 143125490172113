/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import {FormField} from 'shells/form';
import {Checkbox} from 'shells/checkbox';

type OwnProps = {|
    name: string,
    title: string | React.Node,
    isLarge?: boolean,
    isTopAligned?: boolean,
    customCheckboxStyle?: Object,
|};

type Props = {...$Exact<FieldProps>, ...OwnProps};

export function TinyIntroCheckbox({
    title,
    input,
    meta,
    isLarge = true,
    isTopAligned,
    customCheckboxStyle,
    ...restProps
}: Props) {
    return (
        <FormField
            innerId={input.name}
            errorMessage={meta.error && meta.submitFailed ? meta.error : null}
        >
            <Checkbox
                {...input}
                {...restProps}
                checked={input.value}
                label={title}
                hasError={meta.error && meta.submitFailed}
                isLarge={isLarge}
                isTopAligned={isTopAligned}
                customCheckboxStyle={customCheckboxStyle}
            />
        </FormField>
    );
}
