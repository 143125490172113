/* @flow */

import * as React from 'react';
import {withRouter, type ContextRouter} from 'react-router-dom';

import {Header, Subheader} from 'shells/typography';
import {Button} from 'shells/button';

import './reset-success-form.css';

type Props = {...ContextRouter};

export function ResetSuccessFormComponent(props: Props) {
    return (
        <>
            <Header>Password set!</Header>
            <div styleName='text'>
                <Subheader>Use your new password the next time you log in to Nutshell</Subheader>
            </div>
            <div styleName='button'>
                <Button
                    onClick={() => {
                        props.history.push('/auth');
                    }}
                    variant='primary'
                    size='xlarge'
                    isFullWidth={true}
                >
                    Log in
                </Button>
            </div>
        </>
    );
}

export const ResetSuccessForm = withRouter<{||}>(ResetSuccessFormComponent);
