/* @flow */

import * as React from 'react';
import type {FieldProps} from 'redux-form';
import {FormField} from 'shells/form';
import {RadioGroup, RadioButton} from 'shells/radio';
import {TextField} from 'shells/textfield';

type OwnProps = {|
    title: string,
    options: {
        value: string,
        title: string,
    }[],
    isRequired?: boolean,
    labelIsTwoLines?: boolean,
    includeOther?: boolean,
|};

type Props = {...$Exact<FieldProps>, ...OwnProps};

export function TinyIntroRadioButtons(props: Props) {
    const [selectedValue, setSelectedValue] = React.useState<?string>('');
    const [textfieldValue, setTextfieldValue] = React.useState<string>('');

    const options = props.includeOther
        ? props.options.concat({value: 'other', title: 'Other'})
        : props.options;

    React.useEffect(() => {
        if (selectedValue) {
            if (selectedValue === 'other') {
                props.input.onChange(textfieldValue);
            } else {
                props.input.onChange(selectedValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue, textfieldValue]);

    const onChange = (selection) => {
        setSelectedValue(selection);
    };

    const getRadioContent = (option) => {
        if (option.value === 'other') {
            return (
                <div className='flex align-center'>
                    <div style={{marginRight: '8px'}}>Other:</div>
                    <TextField
                        value={textfieldValue}
                        onChange={(value) => setTextfieldValue(value)}
                        onFocus={() => setSelectedValue('other')}
                    />
                </div>
            );
        } else {
            return option.title;
        }
    };

    return (
        <FormField
            innerId={props.input.name}
            label={props.title}
            errorMessage={props.meta.error && props.meta.submitFailed ? props.meta.error : null}
            isRequired={props.isRequired}
            labelIsTwoLines={props.labelIsTwoLines}
        >
            <RadioGroup name={props.input.name} selectedValue={selectedValue} onChange={onChange}>
                {options.map((option) => {
                    return (
                        <RadioButton key={option.value} value={option.value}>
                            {getRadioContent(option)}
                        </RadioButton>
                    );
                })}
            </RadioGroup>
        </FormField>
    );
}
