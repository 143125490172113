/* @flow */
import * as React from 'react';
import {Button} from 'shells/button';
import {ButtonGroup} from 'shells/button-group';
import {LoadingIcon, RepeatIcon} from 'shells/icon';
import {colors} from '../colors';

import './mfa-recovery-codes.css';

type Props = {
    codes: string[],
    usedCodes?: string[],
    isLoading: boolean,
    showRegenerateButton?: boolean,
    onCodesSaved?: () => void,
    generateNewCodes?: () => void,
    getRegenerateButtonRef?: any,
};

export function MfaRecoveryCodes(props: Props) {
    const [didCopyCodes, setDidCopyCodes] = React.useState<boolean>(false);
    const {codes} = props;

    const getCodesAsList = () => {
        let codesList = '';
        for (let i = 0; i < codes.length; i++) {
            codesList = codesList.concat(codes[i], i >= codes.length - 1 ? '' : ', ');
        }

        return codesList;
    };

    const codesHaveBeenSaved = () => {
        if (props.onCodesSaved) {
            props.onCodesSaved();
        }
    };

    const copyCodes = () => {
        navigator.clipboard.writeText(getCodesAsList());
        setDidCopyCodes(true);

        setTimeout(() => {
            setDidCopyCodes(false);
        }, 3000);

        codesHaveBeenSaved();
    };

    const printCodes = () => {
        const recoveryCodePrint = window.open();
        recoveryCodePrint.document.title = 'Nutshell Recovery Codes';
        recoveryCodePrint.document.body.innerHTML = `Nutshell Recovery Codes: ${getCodesAsList()}`;
        recoveryCodePrint.print();

        codesHaveBeenSaved();
    };

    const downloadCodes = () => {
        const text = getCodesAsList();

        const element = document.createElement('a');
        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
        element.setAttribute('download', 'nutshell-recovery-codes.txt');

        element.style.display = 'none';
        if (document.body) {
            document.body.appendChild(element);
        }

        element.click();

        if (document.body) {
            document.body.removeChild(element);
        }

        codesHaveBeenSaved();
    };

    const {getRegenerateButtonRef} = props;

    return (
        <>
            {props.isLoading ? (
                <div styleName='loading-container'>
                    <LoadingIcon fill={colors.greyLt} />
                </div>
            ) : (
                <div styleName='recovery-codes-container' onCopy={codesHaveBeenSaved}>
                    <div styleName='recovery-codes-inner-container'>
                        {props.codes.map((code) => {
                            return (
                                <div styleName='code' key={code}>
                                    {code}
                                </div>
                            );
                        })}

                        {props.usedCodes
                            ? props.usedCodes.map((code) => {
                                  return (
                                      <div styleName='usedCode' key={code}>
                                          {code}
                                      </div>
                                  );
                              })
                            : undefined}
                    </div>
                </div>
            )}

            <div styleName='buttons-container'>
                {props.showRegenerateButton ? (
                    <Button
                        disabled={props.isLoading}
                        size='big'
                        variant='primary'
                        onClick={props.generateNewCodes}
                        styleName='generate-button'
                        icon={RepeatIcon}
                        getButtonRef={getRegenerateButtonRef}
                    >
                        Generate new codes
                    </Button>
                ) : null}

                <div styleName='recovery-button-group'>
                    <ButtonGroup>
                        <Button
                            disabled={didCopyCodes || props.isLoading}
                            size='big'
                            onClick={copyCodes}
                            styleName='button'
                        >
                            {didCopyCodes ? 'Copied!' : 'Copy'}
                        </Button>
                        <Button
                            disabled={props.isLoading}
                            size='big'
                            variant='primary'
                            onClick={downloadCodes}
                            styleName='button'
                        >
                            Download
                        </Button>
                        <Button
                            disabled={props.isLoading}
                            size='big'
                            variant='primary'
                            onClick={printCodes}
                            styleName='button'
                        >
                            Print
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </>
    );
}
