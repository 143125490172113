/* @flow */

import * as React from 'react';
import {Link} from 'shells/link';
import {NavButtons} from 'shells/modal/step-modal/layout';
import {MarketingHeader} from 'shells/typography';
import {DoneScreen} from '../screens/done-screen';
import type {Method} from '../types';

import './done.css';

type Props = {|
    method: Method,
|};

export function DonePage(props: Props) {
    const confettiCanvas = React.useRef(null);

    return (
        <>
            <MarketingHeader>Two-factor authentication is enabled</MarketingHeader>
            <DoneScreen method={props.method} canvasRef={confettiCanvas} />

            <canvas ref={confettiCanvas} styleName='confetti-canvas'></canvas>

            <NavButtons>
                <Link
                    variant='button-primary'
                    size='big'
                    onClick={() => {
                        location.reload();
                    }}
                >
                    Done
                </Link>
            </NavButtons>
        </>
    );
}
