/* @flow */

import * as React from 'react';
import {colors} from 'shells/colors';
import {Button} from 'shells/button';
import {ProgressBar} from 'shells/progress-bar';

import './tiny-introduction-wrapper.css';

type Props = {
    onBack?: () => void,
    children: React.Node,
    progress: number,
};

export class TinyIntroductionWrapper extends React.PureComponent<Props> {
    render() {
        return (
            <div styleName='tiny-introduction-wrapper'>
                {this.props.progress === 1 ? (
                    undefined
                ) : (
                    <div styleName='progress-bar-container'>
                        <ProgressBar
                            height={5}
                            color={colors.greyLt}
                            percentComplete={this.props.progress}
                        />
                    </div>
                )}
                {this.props.children}
                {this.props.onBack ? (
                    <div styleName='small-screen-back-button'>
                        <Button variant='text-secondary' onClick={this.handleBack}>
                            Previous
                        </Button>
                    </div>
                ) : (
                    undefined
                )}
            </div>
        );
    }

    handleBack = () => {
        if (this.props.onBack) {
            this.props.onBack();
        }
    };
}
