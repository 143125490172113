/* @flow */

import * as React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ApolloProvider} from 'react-apollo';
import {ApolloProvider as ReactHooksApolloProvider} from '@apollo/react-hooks';
import {Router} from 'react-router-dom';

import {appHistory} from '../history';
import {store} from '../store';
import {nutApolloClient} from '../apollo-client';
import {AuthRoot} from '../ui/auth/auth-root';

import 'auth.less';

const mountDiv = document.querySelector('#pre-auth-react-mount');
if (mountDiv) {
    ReactDOM.render(
        <Provider store={store}>
            <ApolloProvider client={nutApolloClient}>
                <ReactHooksApolloProvider client={nutApolloClient}>
                    <Router history={appHistory}>
                        <AuthRoot />
                    </Router>
                </ReactHooksApolloProvider>
            </ApolloProvider>
        </Provider>,
        mountDiv
    );
}
